import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { DatePicker } from "@pai-ui/core/components/date-picker";
import { Loading } from "@pai-ui/core/components/loading";
import { Row } from "@pai-ui/core/components/row";
import { Col } from "@pai-ui/core/components/col";
import { Button } from "@pai-ui/core/components/button";
import { message } from "@pai-ui/core/components/message";
import { groupBy } from "lodash";
// import { getApprovedVariants } from "@xai/services/Design";
// import { UserContext } from "@xai/providers/with-auth";
import { debouncePromise } from "@xai/util/helper";
import { UserContext } from "@xai/providers/with-auth";
import DesignVariantBankApprovalServiceHelper from "@xai/pages/design-variant-bank-approval/service-helper";
import { postTableDataTransformer } from "@xai/pages/design-variant-bank-approval/components/design-variant-bank-approval-table/transformer";
import Container from "./container";
import { ItemComponent } from "./Item";
// import { mappedVariants } from "./data";
// import { Filters } from "./filters.js";

const dateFormat = "DD-MMM-YYYY";
const wrapperStyle = {
  // zoom: 1.11111,
};

export const DeploymentSchedular = ({ segregatedVariantBankData }) => {
  const { token, user } = React.useContext(UserContext);
  const service = new DesignVariantBankApprovalServiceHelper(token);

  const [items, setItems] = useState({});
  const [allItems, setAllItems] = useState({});
  const [rawItems, setRawItems] = useState([]);

  const [activeId, setActiveId] = useState();
  const [loading, setLoading] = useState(true);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // const { experimentModule } = React.useContext(UserContext);
  const [deploymentDates, setdeploymentDates] = useState([]);

  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [selectedDatePickerDate, setSelectedDatePickerDate] = useState(null);

  const loadData = async () => {
    setLoading(true);
    // const data = await getApprovedVariants(
    //   "e47fed16-1b14-11ee-99dd-6ac934cd6e11"
    // );
    // // const mappedVariants = data?.data?.data?.experimentVariantBank?.items?.map(
    // //   (x) => ({ ...x, variantDetails: JSON.parse(x.variantDetails) })
    // // );
    const mappedVariants = segregatedVariantBankData?.approvedDataList?.filter(
      (x) => x?.action_status?.toLowerCase() === "approved"
    );

    const modifiedMappedVariants = mappedVariants?.map((x) => ({
      ...x,
      id: x.variantbankId,
      deploymentDate: !x.deploymentDate
        ? "unassigned"
        : moment(x.deploymentDate).format(dateFormat),
    }));

    let variantsGroupedByDeploymentDate = groupBy(
      modifiedMappedVariants,
      (v) => v.deploymentDate
    );

    // intialising unassigned group, if empty.
    variantsGroupedByDeploymentDate["unassigned"] =
      variantsGroupedByDeploymentDate?.["unassigned"] || [];

    const deploymentDatesOptions = Object.keys(variantsGroupedByDeploymentDate)
      ?.filter((x) => x !== "unassigned")
      ?.map((x) => {
        return {
          id: x,
          name: moment(x).format(dateFormat),
        };
      });

    console.log(
      "Variants grouped by deployment date",
      variantsGroupedByDeploymentDate,
      allItems,
      // data,
      deploymentDatesOptions
    );
    setRawItems(mappedVariants);
    setAllItems(variantsGroupedByDeploymentDate || {});
    setItems(variantsGroupedByDeploymentDate || {});
    setdeploymentDates(deploymentDatesOptions || []);
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const onPanelClose = () => {
    setSelectedDatePickerDate(null);
    setOpenDatePicker(false);
  };

  const addDeploymentDate = () => {
    let tempItems = items;
    tempItems[moment(selectedDatePickerDate).format(dateFormat)] = [];
    console.log(
      "Add selectedDatePickerDate",
      moment(selectedDatePickerDate).format(dateFormat),
      tempItems
    );
    setItems(tempItems);
    onPanelClose();
  };

  /**
   * On drag and drop end of a variant, update DB with proposed deployment date info
   */

  const updateVariantDeploymentDate = async ({ deploymentDate, variant }) => {
    console.log("handle updateVariantDeploymentDate", deploymentDate, variant);

    if (
      deploymentDate !== variant.deploymentDate &&
      moment(deploymentDate).format(dateFormat) !==
        moment(variant.deploymentDate).format(dateFormat)
    ) {
      if (deploymentDate === "unassigned") {
        variant.deploymentDate = null;
      } else {
        variant.deploymentDate = new Date(deploymentDate).toISOString(); // #TODO : Check if moment format needs to be added.
      }

      // On each action change, add log to experiment_variant_bank_user_logs
      variant?.experiment_variant_bank_user_logs?.push({
        userName: user?.name || "",
        email: user?.username || "",
        step: "deployment scheduler",
        action: `deployment date changed to - ${
          variant.deploymentDate
            ? moment(variant.deploymentDate).format(dateFormat)
            : "unassigned"
        }`,
        time: new Date().toISOString(),
      });

      const finalDataSet = await postTableDataTransformer([variant]);
      console.log("handle updateVariantDeploymentDate api call", finalDataSet);

      // API Call to be made for updateVariantDeploymentDate
      await service
        .bulkUpdateExperimentVarianBankList(finalDataSet)
        .then((res) => {
          console.log(
            "updateVariantDeploymentDate bulkUpdateExperimentVarianBankList res",
            res
          );
          variant.deploymentDate =
            variant.deploymentDate !== null
              ? moment(variant.deploymentDate).format(dateFormat)
              : "unassigned";
          /** */
          // setItems((prevState) => {
          //   variant.deploymentDate =
          //     variant.deploymentDate !== null
          //       ? moment(variant.deploymentDate).format(dateFormat)
          //       : "unassigned";
          //   console.log("variant final update", prevState, variant);
          //   return {
          //     ...prevState,
          //     [deploymentDate]: prevState[deploymentDate]?.map((x) => {
          //       x.id === variant.id ? variant : x;
          //     }),
          //   };
          // });
          message["success"]("Deployment Date modified successfully!");
        })
        .catch((err) => {
          console.log(
            "updateVariantDeploymentDate bulkUpdateExperimentVarianBankList Error",
            err
          );
          message["error"]("Deployment Date modification failed");

          // On error show toaster that notifies user about failure on approve click.
        });
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div style={wrapperStyle}>
      {/* <Filters /> */}
      <div
        style={{
          // margin: "10px 0px",
          marginBottom: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <Filters /> */}
        <div></div>
        <div
          style={{
            display: "flex",
            marginTop: "-24px",
          }}
        >
          <DatePicker
            disabledDate={(current) => {
              // Can not select days before today and today
              let disabledDates =
                (current && current < moment().endOf("day")) ||
                // Ref : https://stackoverflow.com/a/63070975/4672396
                Object.keys(items)
                  ?.filter((x) => x !== "unassigned")
                  ?.find(
                    (date) =>
                      moment(date).format(dateFormat) ===
                      moment(current).format(dateFormat)
                  );
              return disabledDates;
            }}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            style={{ visibility: "hidden", width: 0, padding: 0 }}
            placement={"bottomLeft"}
            popupStyle={{ zoom: 1.1111111 }} // Popover tooltip positioning.
            placeholder={"Select new deployment date"}
            showToday={false}
            format={dateFormat}
            open={openDatePicker}
            onFocus={() => {
              setOpenDatePicker(true);
            }}
            onBlur={onPanelClose}
            onChange={(date) => {
              setSelectedDatePickerDate(date);
            }}
            value={selectedDatePickerDate}
            renderExtraFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  padding: "10px 0px",
                }}
              >
                <Button
                  label="Add Date"
                  ghost
                  size={"small"}
                  onClick={addDeploymentDate}
                  disabled={!selectedDatePickerDate}
                />
              </div>
            )}
          />
          <Button
            onClick={() => setOpenDatePicker(!openDatePicker)}
            onBlur={onPanelClose}
            size={"small"}
            ghost
          >
            New Deployment Date
          </Button>
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragOver={(e) => {
          debouncePromise(handleDragOver(e), 5000);
        }}
        onDragEnd={handleDragEnd}
      >
        <Row gutter={[20, 20]}>
          <Col
            span={17}
            style={{
              height: "90vh",
              overflowY: "auto",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Row gutter={[20, 20]}>
              {Object.keys(items)
                ?.filter((x) => x !== "unassigned")
                ?.sort((a, b) => moment(b).diff(moment(a)))
                ?.map((x) => (
                  <Col key={x} span={24}>
                    <Container
                      id={x}
                      items={items[x]}
                      deploymentDates={deploymentDates}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
          <Col
            span={7}
            style={{
              height: "90vh",
              /*  overflowY: "auto", */ paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <Container
              key="unassigned"
              id="unassigned"
              items={items["unassigned"]}
              deploymentDates={deploymentDates}
            />
          </Col>
        </Row>
        <DragOverlay>
          {activeId ? <ItemComponent id={activeId} /> : null}
        </DragOverlay>
      </DndContext>
    </div>
  );

  function findContainer(id) {
    if (id in items) {
      return id;
    }

    return Object.keys(items).find((key) =>
      items[key].some((item) => item.id === id)
    );
  }

  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;

    console.log("handleDragStart", { active, event, id });

    setActiveId(rawItems.filter((x) => x.id === id)?.[0]);
  }

  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    console.log({ id, overId });

    // Find the containers
    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);
    // console.log({ activeContainer, overContainer });

    console.log("handleDragOver", {
      active,
      over,
      event,
      id,
      activeContainer,
      overContainer,
    });

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = prev[activeContainer];
      const overItems = prev[overContainer];

      // Find the indexes for the items
      const activeIndex = activeItems?.findIndex((item) => item.id === id);
      const overIndex = overItems?.findIndex((item) => item.id === overId);

      console.log({ activeIndex, overIndex, prev });

      let newIndex;
      if (overId in prev) {
        // We're at the root droppable of a container
        newIndex = overItems.length + 1;
      } else {
        const isBelowLastItem =
          over &&
          overIndex === overItems?.length - 1 &&
          draggingRect?.offsetTop > over?.rect?.offsetTop + over?.rect?.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex =
          overIndex >= 0 ? overIndex + modifier : overItems?.length + 1;
      }

      return {
        ...prev,
        [activeContainer]: [
          ...prev[activeContainer]?.filter((item) => item.id !== active.id),
        ],
        [overContainer]: [
          ...prev[overContainer]?.slice(0, newIndex),
          items[activeContainer]?.[activeIndex],
          ...prev[overContainer]?.slice(newIndex, prev[overContainer].length),
        ],
      };
    });
  }

  function handleDragEnd(event) {
    const { active, over } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    console.log("handleDragEnd", {
      active,
      over,
      event,
      id,
      activeContainer,
      overContainer,
    });

    const activeIndex = items[activeContainer]?.findIndex(
      (item) => item.id === active.id
    );
    const overIndex = items[overContainer]?.findIndex(
      (item) => item.id === overId
    );

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer !== overContainer
    ) {
      return;
    }

    console.log("handleDragEnd 2", {
      active,
      over,
      event,
      id,
      activeContainer,
      overContainer,
      activeIndex,
      overIndex,
    });

    if (activeIndex !== overIndex) {
      setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }

    setActiveId(null);
    // Update Deployment date, when container has changed
    updateVariantDeploymentDate({
      deploymentDate: overContainer,
      variant: items[activeContainer][activeIndex],
    });
  }
};
