import React, { useEffect } from "react";
import lodash from "lodash";
import moment from "moment";
import download from "downloadjs";
import { size } from "lodash";
import { Checkbox } from "@pai-ui/core/components/checkbox";
import { InputSearch } from "@pai-ui/core/components/input";
import { Select } from "@pai-ui/core/components/select";
import { Space } from "@pai-ui/core/components/space";

import { DateRangePicker } from "@pai-ui/core/components/date-range-picker";
import { ZsFilterFilled } from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { exportCsv_ } from "../../../design-variant-bank-approval-table/index.js";
import { UserContext } from "@xai/providers/with-auth";
import DesignVariantBankApprovalServiceHelper from "@xai/pages/design-variant-bank-approval/service-helper";

export const Filters = ({
  allVariants,
  setVariants,
  filtersData,
  setFiltersData,
}) => {
  const { token, experimentModule } = React.useContext(UserContext);
  const service = new DesignVariantBankApprovalServiceHelper(token);
  const Experiment_Id = experimentModule?.selectedExperiment?.id;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;
  const Experiment_Name = experimentModule?.selectedExperiment?.name;

  const csvExportFeature = async () => {
    console.log(
      "csvExportFeature",
      "experiment_id",
      "action_status list",
      "templete"
    );
    // #TODO : API Call to Export draft table data
    let dataConfig = {
      experiment_id: Experiment_Id,
      action_status: ["deployed", "deactivated"], // variant records that are present in draft tab
      templete: experimentTextVariantsType,
    };
    await service
      .csvExportFeatureApi(dataConfig)
      .then(async (res) => {
        console.log("csvExportFeatureApi res", res);
        const blob = res;
        download(
          blob,
          `${Experiment_Id}_${Experiment_Name}_pastDeployed.csv`,
          "text/csv"
        );
        // require("downloadjs")(res, "Report.xlsx");
      })
      .catch((err) => {
        console.log("exportSegmentImpactExplorerTabData Error", err);
      });
  };

  useEffect(() => {
    let tempVariants = [...allVariants];
    tempVariants = tempVariants
      ?.filter(
        (variants) =>
          variants?.subjectLine
            ?.toLowerCase()
            ?.includes(filtersData?.title_bodycopy?.toLowerCase()) ||
          variants?.bodyCopy
            ?.toLowerCase()
            ?.includes(filtersData?.title_bodycopy?.toLowerCase())
      )
      ?.filter((variants) =>
        size(filtersData?.status) == 0
          ? true
          : filtersData?.status?.includes(variants?.action_status)
      )
      ?.filter((varObj) => {
        if (
          !filtersData?.deployment_date?.[0] ||
          !filtersData?.deployment_date?.[1]
        ) {
          return varObj;
        }
        if (
          moment(varObj?.deploymentDate).isBetween(
            filtersData?.deployment_date?.[0],
            filtersData?.deployment_date?.[1]
          )
        ) {
          return varObj;
        } else {
          return;
        }
      });
    setVariants(tempVariants);
  }, [filtersData]);

  const filterChangeHandler = (value, key) => {
    let tempFiltersData = { ...filtersData };
    tempFiltersData = {
      ...tempFiltersData,
      [key]: value,
    };
    setFiltersData(tempFiltersData);
  };

  return (
    <>
      <Space>
        <div>
          <ZsFilterFilled />
          Filters
        </div>
        <div>
          <InputSearch
            placeholder={"Search"}
            value={filtersData?.["title_bodycopy"]}
            onChange={(e) => {
              filterChangeHandler(e?.target?.value, "title_bodycopy");
            }}
          />
        </div>
        <div>
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            style={{ zoom: "1.111111" }}
            placeholder="Status"
            mode="multiple"
            maxTagCount="responsive"
            width={"15vw"}
            value={filtersData?.["status"]}
            options={allVariants
              ?.filter(
                (v, i, a) =>
                  a?.findIndex(
                    (v2) => v2?.action_status === v?.action_status
                  ) === i
              )
              ?.map((variantObj) => {
                return {
                  id: variantObj?.action_status,
                  name: lodash.upperFirst(variantObj?.action_status),
                };
              })}
            onChange={(value) => {
              filterChangeHandler(value, "status");
            }}
          />
        </div>
        <div>
          <DateRangePicker
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            style={{ zoom: "1.111111" }}
            value={filtersData?.["deployment_date"]}
            onChange={(value) => {
              filterChangeHandler(value, "deployment_date");
            }}
          />
        </div>
        <div>
          <Checkbox
            checked={filtersData?.["grouped_by_deployment_date"]}
            onChange={(e) => {
              filterChangeHandler(
                e?.target?.checked,
                "grouped_by_deployment_date"
              );
            }}
          />{" "}
          Group by Deployment Date
        </div>
        <div title={"Export"} onClick={csvExportFeature}>
          {exportCsv_}
        </div>
      </Space>
    </>
  );
};
