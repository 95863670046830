import React from "react";
import { useGenerateContext } from "../../contexts/generate-context/GenerateContext";
import { InputWrapper } from "../../components/input-wrapper/InputWrapper";
import { Button, Col, Input, Popover, Row } from "antd";
import { AspectRatio } from "../../components/aspect-ratio/AspectRatio";
import { CaretRightOutlined } from "@ant-design/icons";
import { ImageUploader } from "../../components/image-uploader/ImageUploader";

export const GenerateImagesSidebar = (props) => {
  const generateContext = useGenerateContext();

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          paddingTop: 40,
          paddingRight: 30,
          paddingLeft: 25,
          flex: 1,
          overflow: "auto",
        }}
      >
        {!generateContext.advanced.value && (
          <InputWrapper
            label={"Prompt"}
            help={
              "What do you want to see? Try to be as descriptive as possible. You can provide a single word, a sentence or an entire paragraph"
            }
          >
            <Input.TextArea
              rows={5}
              value={generateContext.prompt.value}
              onChange={(e) => generateContext.prompt.set(e.target.value)}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="link"
                style={{
                  marginTop: 5,
                  marginBottom: 0,
                  minWidth: 0,
                  padding: 0,
                }}
                onClick={() => {
                  generateContext.prompt.set("|||");
                  generateContext.advanced.set(true);
                }}
              >
                Advanced
              </Button>
            </div>
          </InputWrapper>
        )}
        {generateContext.advanced.value && (
          <InputWrapper
            label={"Advanced Prompt"}
            help="Use these specific prompts to add details to your request"
          >
            <p style={{ marginBottom: 5, marginTop: 5 }}>
              Background &amp; Environment
            </p>
            <Input.TextArea
              rows={5}
              style={{ marginBottom: 25 }}
              value={generateContext.prompt.value.split("|")?.[0]}
              onChange={(e) => generateContext.prompt.update(e.target.value, 0)}
            />
            <p style={{ marginBottom: 5 }}>Character Description</p>
            <Input.TextArea
              rows={5}
              style={{ marginBottom: 25 }}
              value={generateContext.prompt.value.split("|")?.[1]}
              onChange={(e) => generateContext.prompt.update(e.target.value, 1)}
            />
            <p style={{ marginBottom: 5 }}>Character Activity</p>
            <Input.TextArea
              rows={5}
              style={{ marginBottom: 25 }}
              value={generateContext.prompt.value.split("|")?.[2]}
              onChange={(e) => generateContext.prompt.update(e.target.value, 2)}
            />
            <p style={{ marginBottom: 5 }}>Lighting</p>
            <Input.TextArea
              rows={5}
              value={generateContext.prompt.value.split("|")?.[3]}
              onChange={(e) => generateContext.prompt.update(e.target.value, 3)}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="link"
                style={{
                  marginTop: 5,
                  marginBottom: 0,
                  minWidth: 0,
                  padding: 0,
                }}
                onClick={() => {
                  generateContext.advanced.set(false);
                  generateContext.prompt.set("");
                }}
              >
                Back to Basic Prompt
              </Button>
            </div>
          </InputWrapper>
        )}
        <div style={{ marginTop: -40 }}></div>
        <InputWrapper
          label={"Filter"}
          help={
            "Experiment with different styles that can be applied to your image."
          }
        >
          <Popover
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            style={{ zoom: "1.111111" }}
            content={
              <div style={{ width: 400, paddingBottom: 8 }}>
                <h3
                  style={{
                    fontWeight: "bold",
                    marginBottom: 15,
                    marginTop: 5,
                  }}
                >
                  Filters
                </h3>
                <Row gutter={[8, 8]}>
                  {generateContext.preFilters.map((filter, keyIndx) => (
                    <Col span={8} key={keyIndx}>
                      <AspectRatio width={1} height={1}>
                        <div
                          onClick={() => {
                            generateContext.defaultPreFilter.set(filter);
                          }}
                          style={{
                            height: "100%",
                            justifyContent: filter.image
                              ? "flex-end"
                              : "center",
                            alignItems: filter.image ? "flex-start" : "center",
                            display: "flex",
                            flexDirection: "column",
                            padding: 8,
                            borderRadius: generateContext.config.radius / 2,
                            backgroundColor: "rgba(0,0,0,0.5)",
                            border: "1px solid rgba(255,255,255,0.3)",
                            backgroundImage: `url(${filter.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              backgroundColor: "rgba(0,0,0,0.33)",
                            }}
                          ></div>
                          <span style={{ position: "relative", color: "#FFF" }}>
                            {filter.name}
                          </span>
                        </div>
                      </AspectRatio>
                    </Col>
                  ))}
                </Row>
              </div>
            }
            placement="rightTop"
            trigger="click"
          >
            {!!generateContext.defaultPreFilter.value && (
              <div
                style={{
                  border: "1px solid rgba(255,255,255,0.3)",
                  borderRadius: 20,
                  padding: "10px 12px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    width: 35,
                    height: 35,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    border: "1px solid rgba(255,255,255,0.3)",
                    backgroundImage: `url(${generateContext.defaultPreFilter.value.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    marginRight: 10,
                    borderRadius: 5,
                  }}
                ></div>
                <p style={{ margin: 0, flex: 1, fontSize: "16px" }}>
                  {generateContext.defaultPreFilter.value.name}
                </p>
                <CaretRightOutlined />
              </div>
            )}
          </Popover>
        </InputWrapper>
        <InputWrapper
          label={"Orientation"}
          right={
            <Row gutter={[7, 7]} style={{}}>
              <Col span={12}>
                <Button
                  size="medium"
                  type={
                    generateContext.aspectRatio.value === "10:16"
                      ? "primary"
                      : undefined
                  }
                  style={{ width: "100%" }}
                  onClick={() => {
                    generateContext.aspectRatio.set("10:16");
                  }}
                >
                  Portrait
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="medium"
                  type={
                    generateContext.aspectRatio.value === "16:10"
                      ? "primary"
                      : undefined
                  }
                  style={{ width: "100%" }}
                  onClick={() => {
                    generateContext.aspectRatio.set("16:10");
                  }}
                >
                  Landscape
                </Button>
              </Col>
            </Row>
          }
        ></InputWrapper>
        <InputWrapper
          label={"Engine"}
          right={
            <Row gutter={[7, 7]} style={{}}>
              <Col span={12}>
                <Button
                  size="medium"
                  type={
                    generateContext.engine.value === "--v 5.2"
                      ? "primary"
                      : undefined
                  }
                  style={{ width: "100%" }}
                  onClick={() => {
                    generateContext.engine.set("--v 5.2");
                  }}
                >
                  v5.2
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  size="medium"
                  type={
                    generateContext.engine.value === "--niji 5"
                      ? "primary"
                      : undefined
                  }
                  style={{ width: "100%" }}
                  onClick={() => {
                    generateContext.engine.set("--niji 5");
                  }}
                >
                  Niji 5
                </Button>
              </Col>
            </Row>
          }
        ></InputWrapper>
        <InputWrapper
          label={"Seed Image"}
          help={"Upload or draw an image to use as inspiration."}
        >
          <ImageUploader
            image={generateContext.seedImage.value}
            onComplete={generateContext.seedImage.set}
            clear={() => generateContext.seedImage.set("")}
          />
        </InputWrapper>
        <div style={{ marginTop: 30 }}></div>
        <InputWrapper
          label={"Exclude From Image"}
          help={
            "Describe details you don't want in your image like color, objects, or a scenery."
          }
        >
          <Input.TextArea
            rows={5}
            value={generateContext.exclude.value}
            onChange={(e) => generateContext.exclude.set(e.target.value, 3)}
          />
        </InputWrapper>
      </div>
      <div
        style={{ paddingTop: 20, paddingBottom: 5, padding: "20px 25px 20px" }}
      >
        <Row gutter={[10, 10]}>
          <Col span={12}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              disabled={!generateContext.canGenerate()}
              onClick={() => {
                generateContext.generate(props.callback);
              }}
            >
              Generate
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="secondary"
              style={{ width: "100%" }}
              onClick={() => {
                generateContext.reset();
              }}
            >
              Reset
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
