import React, { useEffect, useState } from "react";
import moment from "moment";
// import { Button } from "@pai-ui/core/components/button";
import { Space } from "@pai-ui/core/components/space";
import { Metric } from "@pai-ui/core/components/metric";
import { DateRangePicker } from "@pai-ui/core/components/date-range-picker";
// import { Select } from "@pai-ui/core/components/select";
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { Grid, GridItem } from "@xai/library/containers/grid";
import GenericModalWithResultComponent from "@xai/assets/product-specific-common-components/generic-modal-with-result-component";
import * as Comps from "./components";

// const currentScreen = "at-a-glance";

const ExecutiveAtAGlanceOverview = (/* props */) => {
  const { experimentModule, dispatch: dispatchMethod } =
    React.useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [localDateRangePickerValue, setLocalDateRangePickerValue] = useState(
    []
  );
  const [filters, setFilters] = useState({
    dateRange: [],
  });
  const [aagOverviewSelectedExperiment, setAagOverviewSelectedExperiment] =
    useState(null);

  const [isRequestModalOpen, setIsRequestModalOpen] = useState(true);

  const Load = async () => {
    setTimeout(() => {
      setLoading(false);
      setFilters({
        ...filters,
        dateRange: [moment(), moment().subtract(6, "months")],
      });
      setLocalDateRangePickerValue([moment(), moment().subtract(6, "months")]);
    }, 400);
  };

  useEffect(() => {
    setLoading(true);
    Load();
  }, []);

  useEffect(() => {
    let selectedExperimentID = experimentModule?.selectedExperiment?.id;
    if (
      aagOverviewSelectedExperiment &&
      selectedExperimentID !== aagOverviewSelectedExperiment
    ) {
      console.log(
        "aagOverviewSelectedExperiment",
        aagOverviewSelectedExperiment,
        selectedExperimentID
      );
      let updatedSelectedExperiment = experimentModule?.experimentList?.find(
        (exp) => exp.id === aagOverviewSelectedExperiment
      );
      let finalobj = {
        ...experimentModule,
        selectedExperiment: updatedSelectedExperiment,
      };
      dispatchMethod({
        type: "experimentModule",
        payload: finalobj,
      });
    }
  }, [aagOverviewSelectedExperiment]);

  useEffect(() => {
    let selectedExperimentID = experimentModule?.selectedExperiment?.id;
    if (selectedExperimentID !== aagOverviewSelectedExperiment) {
      console.log(
        "aagOverviewSelectedExperiment selectedExperiment",
        aagOverviewSelectedExperiment,
        selectedExperimentID
      );
      setAagOverviewSelectedExperiment(selectedExperimentID);
    }
  }, [experimentModule.selectedExperiment]);

  return loading ? (
    <LegacyNoData height={"95vh"} loading={true} />
  ) : (
    <div className={"opacity-0-55"}>
      <GenericModalWithResultComponent
        isRequestModalOpen={isRequestModalOpen}
        setIsRequestModalOpen={setIsRequestModalOpen}
      />
      <div
        style={{
          height: "48px",
          marginBottom: "20px",
          display: "flex",
        }}
      >
        <Space style={{ flex: "auto" }} size={"middle"}>
          {
            <Metric
              title="At A Glance"
              subTitle="Executive Overview"
              highlightedElement="subTitle"
              withBackground={false}
            />
          }
        </Space>
        <Space
          style={{
            flex: "auto",
            justifyContent: "flex-end",
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <DateRangePicker
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              style={{ width: "250px", zoom: "1.111111" }}
              allowClear={false}
              onChange={setLocalDateRangePickerValue}
              value={localDateRangePickerValue}
              format={"DD MMM YYYY"}
              ranges={{
                YTD: [moment().startOf("year"), moment()],
                "Current Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
                "Last 4 Weeks": [
                  moment().subtract(4, "weeks").startOf("week"),
                  moment(),
                ],
              }}
            />
          </div>
        </Space>
      </div>
      <Grid
        template={[
          // [
          //   ...Array(22)
          //     .fill()
          //     .map(() => "LHS-dropdown"),
          //   ...Array(23)
          //     .fill()
          //     .map(() => "list-of-experiments"),
          // ],
          [
            ...Array(11)
              .fill()
              .map(() => "radar-chart"),
            ...Array(11)
              .fill()
              .map(() => "kpi-cards"),
            // ...Array(22)
            //   .fill()
            //   .map(() => "viz-chart-area-1"),
            ...Array(23)
              .fill()
              .map(() => "list-of-experiments"),
          ],
          [
            ...Array(22)
              .fill()
              .map(() => "viz-chart-dots"),
            // ...Array(22)
            //   .fill()
            //   .map(() => "viz-chart-area-2"),
            ...Array(23)
              .fill()
              .map(() => "list-of-experiments"),
          ],
        ]}
      >
        {
          // <GridItem area="LHS-dropdown" style>
          //   {/* <LegacyNoData height={"5vh"} /> */}
          //   <Space
          //     size={"middle"}
          //     style={{
          //       width: "100%",
          //       flex: "auto",
          //       justifyContent: "flex-end",
          //     }}
          //   >
          //     <Select
          //       placeholder="Select use case"
          //       style={{ width: "200px" }}
          //       defaultValue={1}
          //       options={[
          //         {
          //           id: 1,
          //           name: "Subject Line",
          //         },
          //         {
          //           id: 2,
          //           name: "Body Copy",
          //         },
          //         {
          //           id: 3,
          //           name: "Email",
          //         },
          //       ]}
          //     />
          //   </Space>
          // </GridItem>
        }
        {
          // <GridItem area="viz-chart-area-1">
          //   {/* <LegacyNoData height={"25vh"} /> */}
          //   <Comps.OverviewUsecaseKpiCards filters={filters} />
          // </GridItem>
        }
        <GridItem area="radar-chart">
          <Comps.OverviewRadarVizChart filters={filters} />
        </GridItem>

        <GridItem area="kpi-cards">
          <Comps.OverviewKpiCards filters={filters} />
        </GridItem>

        <GridItem area="list-of-experiments">
          <Comps.OverviewListOfExperiments
            filters={filters}
            setAagOverviewSelectedExperiment={setAagOverviewSelectedExperiment}
            aagOverviewSelectedExperiment={aagOverviewSelectedExperiment}
          />
        </GridItem>
        <GridItem area="viz-chart-dots">
          <Comps.OverviewVizChartDots filters={filters} />
        </GridItem>

        {
          // <GridItem area="viz-chart-area-2">
          //   {/* <LegacyNoData height={"52vh"} /> */}
          //   <Comps.OverviewUsecaseResultsFromExperiments filters={filters} />
          // </GridItem>
        }
      </Grid>
    </div>
  );
};

export default ExecutiveAtAGlanceOverview;
