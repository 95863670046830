import React, { useState } from "react";
import numeral from "numeral";
import { InformationCircle } from "@styled-icons/heroicons-outline/InformationCircle";
import { Card } from "@pai-ui/core/components/card";
import { Popover } from "@pai-ui/core/components/popover";
import { Ellipsis } from "@pai-ui/core/components/ellipsis";
import { UserContext } from "@xai/providers/with-auth";
// import { Grid, GridItem } from "@xai/library/containers/grid";
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import * as Comps from "./components";
import "./index.css";

const SummaryCard = ({
  product,
  height,
  summaryData,
  setSummaryData,
  variantLabelCountLimit,
  fontSizeCardLabel,
}) => {
  const { format } = React.useContext(UserContext);

  const [imageCarouselList] = useState(product?.product_variant_mapping);
  const transFormedKPIList = product?.kpi_list;
  // const [transFormedKPIList, setTransFormedKPIList] = useState();
  const liftLabel = "Lift";

  // useEffect(() => {
  //   if (product?.kpi_list) {
  //     let tempKPIList = product?.kpi_list;

  //     if (product?.lift_kpi_value) {
  //       tempKPIList.push({
  //         format_id: 1,
  //         kpi_name: "Lift",
  //         kpi_order: product?.lift_kpi_order,
  //         kpi_value: product?.lift_kpi_value
  //       })
  //     }

  //     if (product?.response_metric_kpi_value) {
  //       tempKPIList.push({
  //         format_id: (product?.response_metric_kpi_format),
  //         kpi_name: product?.response_metric_kpi_name,
  //         kpi_order: product?.response_metric_kpi_order,
  //         kpi_value: (product?.response_metric_kpi_value)
  //       })
  //     }

  //     setTransFormedKPIList(tempKPIList?.sort((a, b) => parseInt(a?.kpi_order) - parseInt(b?.kpi_order)));

  //   }
  // }, []);

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        height: `calc(33vh + ${height}px)`,
        opacity: !product?.audience_group_variant_is_active ? "0.7" : "1",
        color: !product?.audience_group_variant_is_active ? "grey" : "inherit",
        // maxHeight: "106vh",
      }}
      bordered={false}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // height: `calc(34vh + ${height}px)`
          // maxHeight: "103vh",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid rgb(48, 48, 48)",
          }}
        >
          {product?.audience_group_variant_label?.includes("||||") ? (
            <Popover
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              style={{ zoom: "1.111111" }}
              placement="top"
              title={`${product?.audience_group_variant_name} - ${
                product?.audience_group_variant_label?.split("||||")?.[0]
              }`}
              content={`Body: ${
                product?.audience_group_variant_label?.split("||||")?.[1]
              }`}
              trigger="hover"
              overlayStyle={{
                maxWidth: "50%",
                zoom: "1.111111",
              }}
            >
              <span>{`${product?.audience_group_variant_name} - ${
                product?.audience_group_variant_label?.split("||||")?.[0]
              }`}</span>
            </Popover>
          ) : (
            <Ellipsis
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              style={{ zoom: "1.111111" }}
              overlayStyle={{
                color: "#181b20",
                zoom: "1.111111",
              }}
              maxWidth={"90%"}
              text={`${
                product?.audience_group_variant_name
              } - ${product?.audience_group_variant_label?.replace(
                "||||",
                ""
              )}`}
            />
          )}
          {product?.variant_info_text?.length > 0 ? (
            <Popover
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              style={{ zoom: "1.111111" }}
              content={
                <div>
                  {product?.variant_info_text &&
                    (product?.variant_info_text?.includes("||||") ? (
                      <div>
                        {product?.variant_info_text
                          ?.split("||||")
                          ?.map((wordSplit, i) => {
                            return <p key={i}>{wordSplit}</p>;
                          })}
                      </div>
                    ) : (
                      <span>{product?.variant_info_text}</span>
                    ))}
                </div>
              }
              overlayStyle={{
                // maxWidth: "50%",
                zoom: "1.111111",
              }}
            >
              <InformationCircle
                style={{ width: "23px", color: "rgba(255,255,255,0.85)" }}
              ></InformationCircle>
            </Popover>
          ) : (
            <></>
          )}
          {/* <Metric
            withBackground={false}
            highlightedElement="subTitle"
            textAlign="right"
            title={
              product["lift_kpi_value"] && (
                <PosNegValue
                  value={product["lift_kpi_value"]}
                  valueFormat={"0.0%"}
                  fontSize={"16px"}
                  ZeroColor={"rgba(225,225,225,0.85)"}
                  NegColor={"rgba(225,225,225,0.85)"}
                  PosColor={"rgba(225,225,225,0.85)"}
                  showCaret={false}
                />
              )
            }
            subTitle={product["lift_kpi_value"] && "Lift"}
          />
          <Metric
            withBackground={false}
            highlightedElement="subTitle"
            textAlign="right"
            title={
              product["response_metric_kpi_value"] && (
                <PosNegValue
                  value={product["response_metric_kpi_value"]}
                  valueFormat={format[product["response_metric_kpi_format"]]}
                  fontSize={"16px"}
                  ZeroColor={"rgba(225,225,225,0.85)"}
                  NegColor={"rgba(225,225,225,0.85)"}
                  PosColor={"rgba(225,225,225,0.85)"}
                  showCaret={false}
                />
              )
            }
            subTitle={product["response_metric_kpi_name"]}
          /> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "20px 0px",
          }}
        >
          <div>
            <Comps.ImageCarousel
              imageCarouselList={imageCarouselList}
              product={product}
              variantLabelCountLimit={variantLabelCountLimit}
              fontSizeCardLabel={fontSizeCardLabel}
            />
          </div>
          <div
            style={{
              display: "none",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px 0px",
            }}
          >
            <Comps.ImageUploader
              experiment_audience_group_variant_id={
                product.experiment_audience_group_variant_id
              }
              imageCarouselList={imageCarouselList}
              summaryData={summaryData}
              setSummaryData={setSummaryData}
            />
          </div>
        </div>
        <div
          style={{
            /* overflowY: "auto", minHeight: 0.2 * height, maxHeight: "63vh", */ padding:
              "0px 10px",
          }}
        >
          {transFormedKPIList?.map((kpi, indx) => {
            return (
              <>
                {
                  <div
                    key={`treatment_revenue_${indx}`}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "7.5px 0px",
                      borderBottom: "1px solid rgb(48, 48, 48)",
                      alignItems: "center",
                      minHeight: "60px",
                    }}
                  >
                    <div
                      key={`treatment_revenue_${indx}_key`}
                      style={{
                        flex: "2 1 auto",
                        paddingRight: "5px",
                      }}
                    >
                      {kpi?.kpi_name}
                    </div>
                    <div
                      key={`treatment_revenue_${indx}_value`}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <div>
                        {[null, undefined].indexOf(kpi?.kpi_value) === -1
                          ? numeral(kpi?.kpi_value)
                              .format(format?.[kpi?.format_id])
                              .toUpperCase()
                          : "--"}
                      </div>
                      <div>
                        {[null, undefined].indexOf(kpi?.kpi_lift) === -1 ? (
                          <div
                            className="custom-kpi-lift-class" /*  style={{ display: "flex", alignItems: "baseline" }} */
                          >
                            <div>
                              {liftLabel}: &nbsp;
                              <PosNegValue
                                value={kpi?.kpi_lift}
                                valueFormat={format?.[kpi?.kpi_lift_format]}
                                showCaret={false}
                                NegColor="#e8484a"
                                ZeroColor="#FFFFFFD9"
                              />
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                }
                {
                  // <Grid
                  //   key={`treatment_revenue_${indx}`}
                  //   style={{
                  //     borderBottom: "1px solid rgb(48, 48, 48)",
                  //     // height: "38px",
                  //   }}
                  //   template={[["key", "key", "value"]]}
                  // >
                  //   <GridItem
                  //     className="product-items-custom-wrapper"
                  //     style={{
                  //       justifySelf: "flex-start" /*  margin: "10px 0px"  */,
                  //     }}
                  //     area="key"
                  //   >
                  //     {kpi?.kpi_name}
                  //   </GridItem>
                  //   <GridItem
                  //     className="product-items-custom-wrapper"
                  //     style={{
                  //       justifySelf: "flex-end",
                  //       margin:
                  //         [null, undefined].indexOf(kpi?.kpi_lift) === -1
                  //           ? "0px"
                  //           : "10px 0px",
                  //     }}
                  //     area="value"
                  //   >
                  //     <div style={{ textAlign: "right" }}>
                  //       {[null, undefined].indexOf(kpi?.kpi_value) === -1
                  //         ? numeral(kpi?.kpi_value)
                  //             .format(format?.[kpi?.format_id])
                  //             .toUpperCase()
                  //         : "--"}
                  //       <br />
                  //       {[null, undefined].indexOf(kpi?.kpi_lift) === -1 ? (
                  //         <div
                  //           className="custom-kpi-lift-class" /*  style={{ display: "flex", alignItems: "baseline" }} */
                  //         >
                  //           <div>
                  //             {liftLabel}: &nbsp;
                  //             <PosNegValue
                  //               value={kpi?.kpi_lift}
                  //               valueFormat={format?.[kpi?.kpi_lift_format]}
                  //               showCaret={false}
                  //               NegColor="#e8484a"
                  //               ZeroColor="#FFFFFFD9"
                  //             />
                  //           </div>
                  //         </div>
                  //       ) : (
                  //         <></>
                  //       )}
                  //     </div>
                  //   </GridItem>
                  // </Grid>
                }
              </>
            );
          })}
        </div>
      </div>
    </Card>
  );
};

export default SummaryCard;
