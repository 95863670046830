import React, { useState, useEffect } from "react";
import numeral from "numeral";
import { Select } from "@pai-ui/core/components/select";
import { Button } from "@pai-ui/core/components/button";
import {
  Visualization,
  TwoByTwoChartConfig,
  VisualizationActionType,
} from "@pai-ui/core/components/visualization";
import { Divider } from "@pai-ui/core/components/divider";
import { UserContext } from "@xai/providers/with-auth";
import { PosNegValue } from "@xai/assets/product-specific-common-components/custom-PosNeg";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import "./index.css";

const TwoByTwoChart = ({
  chartData,
  error,
  loading,
  loadSummaryTabTwoByTwoChartData,
  selectedDateRangeVal,
  selectedVariants,
  selectedAudience,
  endOfExportFileName,
}) => {
  const rootRef = React.useRef();
  const { experimentModule, format } = React.useContext(UserContext);

  const [metricsOptions, setMetricsoption] = useState([]);
  const [currentMetric, setCurrentMetric] = useState();
  const ExperimentResponseMetric =
    experimentModule?.selectedExperiment?.experiment_data?.response_metric;
  const ExperimentComparisonMetric =
    experimentModule?.selectedExperiment?.experiment_data?.summary_chart_metric;
  const ExperimentAudienceGroupAvailable =
    experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]
      ?.audience_group_name;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;
  // const [resetZoomController, setResetZoomController] = useState();

  // const initializeResetZoom = React.useCallback((resetFn) => {
  //   setResetZoomController({ resetFn });
  // }, [currentMetric]);

  useEffect(() => {
    if (chartData) {
      console.log("TwoByTwoChart", chartData);
      setMetricsoption(chartData?.viz_filter);
      const tempDefaultCurrentMetric =
        chartData?.viz_filter?.find(
          (filterObj) => filterObj?.name === ExperimentResponseMetric
        )?.name || chartData?.viz_filter?.[0]?.id;
      setCurrentMetric(tempDefaultCurrentMetric);
    }
  }, [chartData]);

  if (error) {
    return (
      <LegacyNoData height={"69vh"} error={true}>
        <Button
          onClick={() => loadSummaryTabTwoByTwoChartData(selectedDateRangeVal)}
        >
          Try Again
        </Button>
      </LegacyNoData>
    );
  }

  const getFileDownloadName = React.useCallback(
    () =>
      `Variant Performance Comparison - ${
        experimentModule?.selectedExperiment?.name
      } - ${selectedAudience} - ${ExperimentComparisonMetric} - ${currentMetric} - ${endOfExportFileName()}`,
    [currentMetric]
  );

  const processDataForCSV = React.useCallback(
    (data) => {
      data = data.map((item) => {
        // All Properties from filtered data
        let {
          audience_group_variant_label,
          audience_group_variant_name,
          parent_audience_group_variant_name,
          xaxis,
          yaxis,
          yaxis_lift,
          // ...restALLKeyValues
        } = item;

        // console.log("restALLKeyValues", restALLKeyValues);

        let retObj = {
          "Audience Group": parent_audience_group_variant_name,
          Variant: audience_group_variant_name,
          // "Variant - label": audience_group_variant_label,
        };

        if (
          experimentTextVariantsType?.toLowerCase() === "subjectlineandbodycopy"
        ) {
          let textSplit = audience_group_variant_label?.split("||||");
          retObj["Variant - label - Subject line"] = textSplit?.[0] || "";
          retObj["Variant - label - Body copy"] = textSplit?.[1] || "";
        } else {
          retObj["Variant - label"] = audience_group_variant_label || "";
        }

        retObj = {
          ...retObj,
          [ExperimentComparisonMetric]:
            ["", null, undefined].indexOf(xaxis) > -1
              ? ""
              : numeral(xaxis)
                  .format(
                    format?.[
                      chartData?.viz_data?.[currentMetric]?.["x-axis-format"]
                    ]
                  )
                  .toUpperCase(),
          [currentMetric]:
            ["", null, undefined].indexOf(yaxis) > -1
              ? ""
              : numeral(yaxis)
                  .format(
                    format?.[
                      chartData?.viz_data?.[currentMetric]?.["y-axis-format"]
                    ]
                  )
                  .toUpperCase(),
          Lift:
            ["", null, undefined].indexOf(yaxis_lift) > -1
              ? ""
              : numeral(yaxis_lift)
                  .format(
                    format?.[
                      chartData?.viz_data?.[currentMetric]?.[
                        "y-axis-lift-format"
                      ]
                    ]
                  )
                  .toUpperCase(),
        };

        return retObj;
      });
      console.log("processDataForCSV - Variant Performance Comparison", data);
      return data;
      // return [data[0]];
    },
    [currentMetric]
  );

  const renderTooltipContent = React.useCallback(
    (item) => {
      return (
        <div
          style={{
            padding: "10px",
            minHeight: "100px",
            display: "flex",
            flexDirection: "column",
            maxWidth: "300px",
          }}
        >
          {item?.audience_group_variant_label?.includes("||||") ? (
            <>
              <h3 style={{ marginBottom: "3px" }}>
                {`${item?.audience_group_variant_name} - ${
                  item?.audience_group_variant_label?.split("||||")?.[0]
                }`}
              </h3>
              <Divider orientation="left" style={{ margin: "3px 0px" }} />
              <p style={{ margin: "3px 0px 0px 0px" }}>{`${
                item?.audience_group_variant_label?.split("||||")?.[1]
              }`}</p>
            </>
          ) : (
            <h3 style={{ marginBottom: "3px" }}>
              {`${item?.audience_group_variant_name} - ${item?.audience_group_variant_label}`}
            </h3>
          )}
          <div
            style={{
              display: "flex",
              minWidth: "250px",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "35%",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                paddingRight: "5px",
              }}
            >
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#15AFD0",
                }}
              >
                {numeral(item?.yaxis).format(
                  format?.[
                    chartData?.viz_data?.[currentMetric]?.["y-axis-format"]
                  ]
                )}
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <b>
                  {
                    metricsOptions?.find(
                      (metric) => metric?.id === currentMetric
                    )?.name
                  }
                </b>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "65%",
                flexDirection: "column",
                justifyContent: "center",
                padding: "0px 2px",
                borderLeft: "1px solid rgb(48, 48, 48)",
              }}
            >
              {[
                {
                  name: ExperimentComparisonMetric,
                  value: item?.xaxis,
                  format:
                    format?.[
                      chartData?.viz_data?.[currentMetric]?.["x-axis-format"]
                    ],
                },
                {
                  name: "Lift",
                  value: item?.yaxis_lift,
                  format:
                    format?.[
                      chartData?.viz_data?.[currentMetric]?.[
                        "y-axis-lift-format"
                      ]
                    ],
                },
              ].map((x, i) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    padding: "5px",
                  }}
                >
                  {x?.value ? (
                    <>
                      <div
                        style={{
                          paddingRight: "10px",
                          display: "flex",
                        }}
                      >
                        <b>{x.name}:</b>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "flex-end",
                        }}
                      >
                        {x.name === "Lift" ? (
                          <PosNegValue
                            value={x?.value}
                            valueFormat={
                              format?.[
                                chartData?.viz_data?.[currentMetric]?.[
                                  "y-axis-lift-format"
                                ]
                              ]
                            }
                            showCaret={false}
                            NegColor="#e8484a"
                            ZeroColor="#FFFFFFD9"
                          />
                        ) : (
                          numeral(x?.value).format(x?.format).toUpperCase()
                        )}
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    },
    [currentMetric]
  );

  // highlight inactive vaiants in grey color
  const getItemBackground = React.useCallback((item) => {
    if (!item.audience_group_variant_is_active) {
      return "grey";
    }

    return "#15afd0";
  }, []);

  const viewConfig = React.useMemo(() => {
    return new TwoByTwoChartConfig({
      renderTooltipContent,
      series: {
        commonConfig: {
          getItemBackground, // you can also pass it to the specificConfig in each serie
        },
        specificConfig: [
          {
            name: "Series",
            fieldName: {
              name: "audience_group_variant_name",
              x: "xaxis",
              y: "yaxis",
              id: "audience_group_variant_id",
            },
          },
        ],
      },
      axes: {
        x: {
          label: ExperimentComparisonMetric,
          format: {
            value:
              format?.[chartData?.viz_data?.[currentMetric]?.["x-axis-format"]],
          },
        },
        y: {
          label: metricsOptions?.find((metric) => metric?.id === currentMetric)
            ?.name,
          format: {
            value:
              format?.[chartData?.viz_data?.[currentMetric]?.["y-axis-format"]],
          },
        },
      },
      classes: {
        dataLabel: "data-label",
        rootDefault: "root-default",
        tooltip: "tooltip",
        xAxisGroupComponent: "x-axis-group-component",
        yAxisGroupComponent: "y-axis-group-component",
      },
      // zoom: {
      //     initializeResetZoomFunction: initializeResetZoom,
      //   },
    });
  }, [currentMetric]);

  // #TODO : This is a temporary change to handle resetZoom on Select Change. Needs to be replaced when actual fix is available.
  const resetZoom = () => {
    if (rootRef.current) {
      const resetBtn = Array.from(
        document.querySelectorAll(".ant-btn.ant-btn-ghost")
      ).find((x) =>
        Array.from(x.firstChild.classList).some((classname) =>
          classname.includes("resetZoomIcon")
        )
      );
      console.log("RESET_ZOOM_BTN", resetBtn);
      if (resetBtn) {
        resetBtn.click();
      }
    }
  };

  return (
    <>
      {!loading ? (
        <div ref={rootRef} className={"two-by-two-chart-summary-view"}>
          <Visualization
            title="Variant Performance Comparison"
            height={"62vh"}
            headerContent={{
              right: (
                <>
                  <Select
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                    options={metricsOptions}
                    value={currentMetric}
                    style={{
                      width: "150px",
                      zoom: "1.111111",
                    }}
                    onChange={(value) => {
                      setCurrentMetric(value);

                      new Promise((r) => setTimeout(r, 200)).then(() => {
                        resetZoom();
                      });

                      // new Promise((r) => setTimeout(r, 200)).then(() => {
                      //     resetZoomController ? resetZoomController.resetFn() : undefined;
                      // })
                    }}
                  />
                </>
              ),
            }}
            componentsStyles={{
              view: {
                marginTop: "10px",
              },
            }}
            download={{
              getFilename: getFileDownloadName,
              processDataBeforeDownload: processDataForCSV,
            }}
            actions={[
              /* VisualizationActionType.exportJpeg, VisualizationActionType.exportPng, */ VisualizationActionType.exportCsv,
            ]}
            viewConfig={viewConfig}
            dataConfig={{
              structureType: "processed",
            }}
            dataset={{
              data: ExperimentAudienceGroupAvailable
                ? chartData?.viz_data?.[currentMetric]?.data
                    ?.filter(
                      (dataObj) =>
                        dataObj?.parent_audience_group_variant_name ===
                        selectedAudience
                    )
                    ?.filter((dataObj) =>
                      selectedVariants?.includes(
                        dataObj?.audience_group_variant_name
                      )
                    )
                : chartData?.viz_data?.[currentMetric]?.data?.filter(
                    (dataObj) =>
                      selectedVariants?.includes(
                        dataObj?.audience_group_variant_name
                      )
                  ),
            }}
          />
        </div>
      ) : (
        <LegacyNoData height={"69vh"} loading={true} />
      )}
    </>
  );
};

export default TwoByTwoChart;
