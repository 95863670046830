import React, { useState, useEffect } from "react";
import numeral from "numeral";
import {
  Visualization,
  CorrelationMatrixConfig,
  VisualizationActionType,
  VisualizationSortingOrder,
} from "@pai-ui/core/components/visualization";
import { Space } from "@pai-ui/core/components/space";
import { Tooltip } from "@pai-ui/core/components/tooltip";
import { Button } from "@pai-ui/core/components/button";
import { Select } from "@pai-ui/core/components/select";
import { Divider } from "@pai-ui/core/components/divider";
import { UserContext } from "@xai/providers/with-auth";
import LegacyNoData from "@xai/assets/generic-components/legacy-no-data";
import { FilterComponent } from "@xai/assets/generic-components/utils";
import "./index.css";

const CorrelationChart = ({
  chartData,
  error,
  loading,
  loadDetailedViewTabCorrelationChartData,
  selectedDateRangeVal,
  selectedVariants,
  selectedAudience,
  endOfExportFileName,
}) => {
  const [metricsOptions, setMetricsoption] = useState([]);
  const [parametersOptions, setParametersoption] = useState([]);
  const [currentMetric, setCurrentMetric] = useState();
  const [currentParameter, setCurrentParameter] = useState();
  const [filteredChartData, setFilteredChartData] = useState();

  const { format: globalFormat, experimentModule } =
    React.useContext(UserContext);

  const ExperimentResponseMetric =
    experimentModule?.selectedExperiment?.experiment_data?.response_metric;
  const ExperimentdDefaultCohortCategory =
    experimentModule?.selectedExperiment?.experiment_data
      ?.default_cohort_category;
  const ExperimentAudienceGroupAvailable =
    experimentModule?.selectedExperiment?.experiment_data?.variant_setup?.[0]
      ?.audience_group_name;
  const experimentTextVariantsType =
    experimentModule?.selectedExperiment?.experiment_data
      ?.experiment_text_variants_type;
  const ChartExcludeFieldsArray = [
    "parent_audience_group_variant_name",
    "format",
    "audience_group_variant_label",
    "audience_group_variant_is_active",
  ];

  useEffect(() => {
    if (chartData) {
      setMetricsoption(chartData?.viz_filter);
      const defaultVizFilterObj = chartData?.viz_filter?.[0];
      const tempDefaultCurrentMetric =
        chartData?.viz_filter?.find(
          (filterObj) => filterObj?.name === ExperimentdDefaultCohortCategory
        )?.name || defaultVizFilterObj?.id;
      const tempParameteroptions =
        chartData?.viz_filter?.find(
          (filterObj) => filterObj?.id === tempDefaultCurrentMetric
        )?.parameter_options || defaultVizFilterObj?.parameter_options;
      setParametersoption(tempParameteroptions);
      setCurrentMetric(tempDefaultCurrentMetric);
      const tempDefaultParamMetric =
        tempParameteroptions?.find(
          (findObj) => findObj?.name === ExperimentResponseMetric
        )?.name || defaultVizFilterObj?.parameter_options?.[0]?.id;
      setCurrentParameter(tempDefaultParamMetric);
    }
  }, [chartData]);

  useEffect(() => {
    if (currentMetric && currentParameter) {
      const tempfilteredChartData = ExperimentAudienceGroupAvailable
        ? chartData?.viz_data?.[currentMetric]?.[currentParameter]
            ?.filter(
              (dataObj) =>
                dataObj?.parent_audience_group_variant_name === selectedAudience
            )
            ?.filter((dataObj) =>
              selectedVariants?.includes(dataObj?.audience_group_variant_name)
            )
        : chartData?.viz_data?.[currentMetric]?.[currentParameter]?.filter(
            (dataObj) =>
              selectedVariants?.includes(dataObj?.audience_group_variant_name)
          );
      setFilteredChartData(tempfilteredChartData);
    }
  }, [currentMetric, currentParameter, selectedVariants, selectedAudience]);

  const renderDataCell = React.useCallback(
    (data, cellValue, { columnName, rowName }) => {
      const localVariantLabel =
        filteredChartData?.find(
          (dataObj) => dataObj?.audience_group_variant_name === rowName
        )?.audience_group_variant_label || "";
      return [null, undefined].indexOf(cellValue) === -1 ? (
        <Tooltip
          placement="topRight"
          color={"#111419"}
          overlayStyle={{ zoom: 1.1111111, minWidth: "300px" }} // Popover tooltip positioning.
          title={
            <>
              <div
                style={{
                  padding: "10px",
                  minHeight: "100px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {localVariantLabel?.includes("||||") ? (
                  <>
                    <h3 style={{ marginBottom: "3px" }}>
                      {`${data?.audience_group_variant_name} - ${
                        localVariantLabel?.split("||||")?.[0]
                      }`}
                    </h3>
                    <Divider orientation="left" style={{ margin: "3px 0px" }} />
                    <p style={{ opacity: 0.7, margin: "3px 0px 7px 0px" }}>{`${
                      localVariantLabel?.split("||||")?.[1]
                    }`}</p>
                  </>
                ) : (
                  <h3 style={{ marginBottom: "3px" }}>
                    {`${data?.audience_group_variant_name} - ${localVariantLabel}`}
                  </h3>
                )}
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      width: "35%",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      paddingRight: "5px",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        textAlign: "center",
                        color: "#15AFD0",
                      }}
                    >
                      {numeral(cellValue)
                        .format(globalFormat?.[filteredChartData?.[0]?.format])
                        .toUpperCase()}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      <b style={{ opacity: 0.7 }}>
                        {
                          parametersOptions?.find(
                            (para) => para?.id === currentParameter
                          )?.name
                        }
                      </b>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "65%",
                      flexDirection: "column",
                      justifyContent: "center",
                      borderLeft: "1px solid rgb(48, 48, 48)",
                    }}
                  >
                    {[
                      {
                        name: metricsOptions?.find(
                          (metric) => metric?.id === currentMetric
                        )?.name,
                        value: columnName,
                      },
                    ].map((x, i) => (
                      <div
                        key={i}
                        style={{
                          display: "flex",
                          padding: "5px",
                          opacity: 0.7,
                        }}
                      >
                        <div
                          style={{
                            paddingRight: "10px",
                            display: "flex",
                          }}
                        >
                          <b>{x.name}:</b>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-end",
                            paddingRight: "15px",
                          }}
                        >
                          {x.format
                            ? numeral(x.value)
                                .format(globalFormat?.[x?.format])
                                .toUpperCase()
                            : x.value}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          }
        >
          {numeral(cellValue)
            .format(globalFormat?.[filteredChartData?.[0]?.format])
            .toUpperCase()}
        </Tooltip>
      ) : (
        <>-</>
      );
    },
    [filteredChartData]
  );

  const renderNameCell = React.useCallback(
    (data, cellValue, { rowName }) => {
      const localVariantLabel =
        filteredChartData?.find(
          (dataObj) => dataObj?.audience_group_variant_name === rowName
        )?.audience_group_variant_label || "";
      const variantActive =
        filteredChartData?.find(
          (dataObj) => dataObj?.audience_group_variant_name === rowName
        )?.audience_group_variant_is_active || "";
      return (
        <Tooltip
          overlayStyle={{ zoom: 1.1111111 }}
          title={
            <>
              {localVariantLabel?.includes("||||") ? (
                <>
                  <h3>
                    {`${data?.audience_group_variant_name} - ${
                      localVariantLabel?.split("||||")?.[0]
                    }`}
                  </h3>
                  <Divider orientation="left" style={{ margin: "3px 0px" }} />
                  <p style={{ opacity: 0.7 }}>{`${
                    localVariantLabel?.split("||||")?.[1]
                  }`}</p>
                </>
              ) : (
                <h3>
                  {`${data?.audience_group_variant_name} - ${localVariantLabel}`}
                </h3>
              )}
            </>
          }
        >
          {localVariantLabel?.split("||||")?.[0]?.length > 40 ? (
            <span className={!variantActive && "opacity-0-55"}>{`${
              data?.audience_group_variant_name
            } - ${localVariantLabel
              ?.split("||||")?.[0]
              ?.slice(0, 40)
              ?.concat("...")}`}</span>
          ) : (
            <span className={!variantActive && "opacity-0-55"}>{`${
              data?.audience_group_variant_name
            } - ${localVariantLabel?.split("||||")?.[0]}`}</span>
          )}
        </Tooltip>
      );
    },
    [filteredChartData]
  );

  const metricChangeHandler = (value) => {
    setCurrentMetric(value);
    const filteredVizObjParmas = chartData?.viz_filter?.find(
      (filObj) => filObj?.id === value
    )?.parameter_options;
    setParametersoption(filteredVizObjParmas);
    const tempDefaultParamMetric =
      filteredVizObjParmas?.find(
        (findObj) => findObj?.name === ExperimentResponseMetric
      )?.name || filteredVizObjParmas?.[0]?.id;
    setCurrentParameter(tempDefaultParamMetric);
  };

  const getFileDownloadName = React.useCallback(
    () =>
      `Variant Performance Spectrum - ${
        experimentModule?.selectedExperiment?.name
      } - ${selectedAudience} - ${currentMetric} - ${currentParameter} - ${endOfExportFileName()}`,
    [currentMetric, currentParameter]
  );

  const processDataForCSV = React.useCallback((data) => {
    data = data.map((item) => {
      // All Properties from filtered data
      let {
        // eslint-disable-next-line no-unused-vars
        audience_group_variant_is_active,
        audience_group_variant_label,
        audience_group_variant_name,
        format,
        parent_audience_group_variant_name,
        ...restALLKeyValues
      } = item;

      // Transform restALLKeyValues using data format
      restALLKeyValues = Object.entries(restALLKeyValues).reduce(
        (acc, [key, value]) => {
          acc[key] =
            ["", null, undefined].indexOf(value) > -1
              ? null
              : numeral(value).format(globalFormat?.[format]).toUpperCase();

          return acc;
        },
        {}
      );
      // console.log("restALLKeyValues", restALLKeyValues);

      let retObj = {
        "Audience Group": parent_audience_group_variant_name,
        Variant: audience_group_variant_name,
        //   "Variant - label": audience_group_variant_label,
        //   //   "Data - Format": globalFormat?.[format],
        //   ...restALLKeyValues,
      };

      if (
        experimentTextVariantsType?.toLowerCase() === "subjectlineandbodycopy"
      ) {
        let textSplit = audience_group_variant_label?.split("||||");
        retObj["Variant - label - Subject line"] = textSplit?.[0] || "";
        retObj["Variant - label - Body copy"] = textSplit?.[1] || "";
      } else {
        retObj["Variant - label"] = audience_group_variant_label;
      }

      retObj = {
        ...retObj,
        ...restALLKeyValues,
      };
      return retObj;
    });
    //   console.log("processDataForCSV", data);
    return data;
    // return [data[0]];
  }, []);

  if (error) {
    return (
      <LegacyNoData height={"55vh"} error={true}>
        <Button
          onClick={() =>
            loadDetailedViewTabCorrelationChartData(selectedDateRangeVal)
          }
        >
          Try Again
        </Button>
      </LegacyNoData>
    );
  }

  return (
    <>
      {loading ? (
        <LegacyNoData height={"55vh"} loading={true} />
      ) : (
        <>
          {
            <div className={"correlation-chart-detailed-view"}>
              <Visualization
                title="Variant Performance Spectrum"
                height={"auto"}
                maxHeight="480px"
                componentsStyles={{
                  root: {
                    overflowY: "auto",
                    overflowX: "hidden",
                  },
                }}
                width={"100%"}
                headerHeight={60}
                headerContent={{
                  right: (
                    <Space>
                      <FilterComponent title="Select segmentation variable">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          options={metricsOptions}
                          value={currentMetric}
                          style={{
                            width: "200px",
                            zoom: "1.111111",
                          }}
                          onChange={(value) => metricChangeHandler(value)}
                        />
                      </FilterComponent>
                      <FilterComponent title="Select Parameter">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentElement
                          }
                          options={parametersOptions}
                          value={currentParameter}
                          style={{
                            width: "200px",
                            zoom: "1.111111",
                          }}
                          onChange={(value) => setCurrentParameter(value)}
                        />
                      </FilterComponent>
                    </Space>
                  ),
                }}
                download={{
                  getFilename: getFileDownloadName,
                  processDataBeforeDownload: processDataForCSV,
                }}
                actions={[
                  /* VisualizationActionType.exportJpeg, VisualizationActionType.exportPng, */ VisualizationActionType.exportCsv,
                ]}
                viewConfig={
                  new CorrelationMatrixConfig({
                    stickiness: {
                      header: true,
                      nameColumn: true,
                    },
                    renderDataCell,
                    renderNameCell,
                    classes: {
                      headerNameCellText: "header-name-cell-text",
                      rowNameCell: "row-name-cell",
                      cell: "cell",
                      emptyCell: "empty-cell",
                      root: "body",
                    },
                    gradientComputationStrategy: "feature",
                    excludeFields: filteredChartData?.[0]
                      ? Object.keys(filteredChartData?.[0])?.filter(
                          (item) => ChartExcludeFieldsArray?.indexOf(item) >= 0
                        )
                      : [],
                    rowNameField: "audience_group_variant_name",
                    columnWidth: {
                      default: "fill",
                      label: 225,
                    },
                    format: {
                      value:
                        filteredChartData?.[0] &&
                        filteredChartData?.[0]?.["format"]
                          ? globalFormat?.[
                              Object.entries(filteredChartData?.[0])?.find(
                                (item) => item?.[0]?.includes("format")
                              )?.[1]
                            ]
                          : null,
                    },
                    baseColors: ["#810d16", "#15afd0", "#01872a"],
                    legend: {
                      show: true,
                      labels: [
                        {
                          text: "Minimum",
                        },
                        {
                          text: "Maximum",
                        },
                      ],
                    },
                    sorting: {
                      enabled: true,
                      defaultOrder: VisualizationSortingOrder.desc,
                    },
                  })
                }
                dataConfig={{
                  structureType: "processed",
                }}
                dataset={{ data: filteredChartData || [] }}
              />
            </div>
          }
        </>
      )}
    </>
  );
};

export default CorrelationChart;
