import React, { useState, useEffect } from "react";
import { size, startCase } from "lodash";
import { Space } from "@pai-ui/core/components/space";
import { InputSearch } from "@pai-ui/core/components/input";
import { Select } from "@pai-ui/core/components/select";
import { Dropdown } from "@pai-ui/core/components/dropdown";
import { EmptyData } from "@pai-ui/core/components/empty-data";
import { Pagination } from "@pai-ui/core/components/pagination";
import { Menu } from "@pai-ui/core/components/menu";
import { UserContext } from "@xai/providers/with-auth";
import {
  ZsFilterFilled,
  ZsSignalFilled,
} from "@xai/assets/generic-ant-d-components/quick-access-ant-d";
import { Panel as LegacyPanel } from "@xai/library/legacy/containers/layout";
import { ListItem } from "./listItem";

const TestLibraryListing = ({ handleSetFavorite, onMarkFavFinishLoad }) => {
  const {
    status: statusOptions,
    genericOptions,
    experimentModule,
  } = React.useContext(UserContext);
  const experimentList = experimentModule?.experimentList;

  const pageSize = 7;

  const [currentPage, setCurrentPage] = useState(1);
  const [dataPagination, setDataPagination] = useState({});
  const [paginationCountVisible, setPaginationCount] = useState(0);
  const [filteredData, setFilteredData] = useState(experimentList);

  const GetDataForPagination = (Data) => {
    let rawData = Data ? [...Data] : [...experimentList];
    let dataPagination = {};

    for (let i = 0; i < rawData.length; i++) {
      let page = Math.floor(i / pageSize);

      if (dataPagination[page]) {
        dataPagination[page].push(rawData[i]);
      } else {
        let arr = [];
        arr.push(rawData[i]);
        dataPagination[page] = arr;
      }
    }

    setDataPagination(dataPagination);
    setFilteredData(dataPagination[0]);
    setPaginationCount(Data ? Data.length : experimentList.length);
    setCurrentPage(1);
  };

  const handlePaginationChange = (pageNumber) => {
    if (currentPage !== pageNumber) {
      let paginatedCampaigns = [...dataPagination[pageNumber - 1]];
      setCurrentPage(pageNumber);
      setFilteredData(paginatedCampaigns);
    }
  };

  const [textFilter, setTextFilter] = useState("");
  const [response_metric, setResponse_metric] = useState([]);
  const [status, setStatus] = useState([]);
  const [sortType, setSortType] = useState({
    name: "is_favorite",
    label: "Favorite",
    how: -1,
  });

  const SortDropDownValues = {
    1: { name: "is_favorite", label: "Favorite", how: -1 },
    2: { name: "name", label: "Name Asc.", how: 1 },
    3: { name: "name", label: "Name Desc.", how: -1 },
    4: {
      name: "response_metric",
      label: "Response Metric Asc.",
      how: 1,
    },
    5: {
      name: "response_metric",
      label: "Response Metric Desc.",
      how: -1,
    },
    6: {
      name: "variant_count",
      label: "Variants Asc.",
      how: 1,
    },
    7: {
      name: "variant_count",
      label: "Variants Desc.",
      how: -1,
    },
    8: {
      name: "expected_lift",
      label: "Expected Lift Asc.",
      how: 1,
    },
    9: {
      name: "expected_lift",
      label: "Expected Lift Desc.",
      how: -1,
    },
    10: {
      name: "lift",
      label: "Observed Lift Asc.",
      how: 1,
    },
    11: {
      name: "lift",
      label: "Observed Lift Desc.",
      how: -1,
    },
    12: {
      name: "start_time",
      label: "Go-Live Date Asc.",
      how: 1,
    },
    13: {
      name: "start_time",
      label: "Go-Live Date Desc.",
      how: -1,
    },
    // 12: { name: "created_at", label: "Created At(Latest)", how: -1 },
    // 13: { name: "created_at", label: "Created At(Oldest)", how: 1 },
  };

  const handleFavoriteToggle = async (id, value) => {
    //call API check for response and then update as per result
    const result = handleSetFavorite(id, value);
    console.log("result from SetFavorite api", id, value, result);
  };

  useEffect(() => {
    if (experimentList) {
      console.log(
        "experimentList",
        experimentList,
        response_metric,
        textFilter,
        status,
        sortType
      );
      let filteredData = [...experimentList]
        ?.filter((a) =>
          size(response_metric) == 0
            ? true
            : response_metric.includes(a.response_metric)
        )
        ?.filter((a) => (size(status) == 0 ? true : status.includes(a.status)))
        ?.filter((a) => a.name.toLowerCase().includes(textFilter.toLowerCase()))
        ?.sort(
          (x, y) =>
            sortType.how * (x[sortType.name] > y[sortType.name] ? 1 : -1)
        );

      console.log("filtered", filteredData);
      // setFilteredCampaigns(filtered);
      GetDataForPagination(filteredData);
    }
  }, [experimentList, textFilter, response_metric, status, sortType]);

  return (
    <>
      {/* search and filter components span */}
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
        }}
      >
        <Space style={{ /* marginLeft: "10px", */ flex: "auto" }}>
          <span>
            <ZsFilterFilled />
            Filters:{" "}
          </span>
          <InputSearch
            placeholder={"Search"}
            onChange={(e) => {
              setTextFilter(e.target.value);
            }}
            style={{
              width: "14vw",
              // marginBottom: "25px",
              paddingLeft: "10px",
            }}
          />
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch
            style={{ width: "14vw", paddingLeft: "10px", zoom: "1.111111" }}
            mode="multiple"
            allowClear
            placeholder="Status"
            options={
              statusOptions
                ? Object.entries(statusOptions)?.map((item) => {
                    return { id: parseInt(item[0]), name: startCase(item[1]) };
                  })
                : null
            }
            onChange={(e) => setStatus(e)}
            maxTagCount={1}
          />
          <Select
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            showSearch
            style={{ width: "15vw", paddingLeft: "10px", zoom: "1.111111" }}
            mode="multiple"
            allowClear
            placeholder="Response Metric"
            // values={dropdown_master?.response_metric?.map((a) => ({
            //   ...a,
            //   name: startCase(a.name),
            // }))}
            options={genericOptions?.response_metric?.map(({ id, name }) => ({
              id: id,
              name: name,
            }))}
            onChange={(e) => setResponse_metric(e)}
            maxTagCount={1}
          />

          <Dropdown
            overlay={
              <Menu
                onClick={(x) =>
                  setSortType(
                    SortDropDownValues[parseInt(x.key)]
                      ? SortDropDownValues[parseInt(x.key)]
                      : {}
                  )
                }
                items={Object.keys(SortDropDownValues).map((x) => {
                  return {
                    id: x,
                    name: SortDropDownValues[x].label,
                  };
                })}
              />
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            style={{ zoom: "1.111111" }}
          >
            <div
              style={{
                /*  marginTop: "2%" */ width: "15vw",
                paddingLeft: "10px",
              }}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <ZsSignalFilled
                  rotate={-90}
                  style={{ transform: "scaleX(-1)" }}
                />{" "}
                Sort By:
              </a>
              <span>&nbsp;{sortType.label}</span>
            </div>
          </Dropdown>
        </Space>
        <Space
          style={{
            flex: "auto",
            justifyContent: "flex-end",
          }}
        >
          {/* <Link to="./build">
            <Button
              style={{ float: "right" }}
              type="primary"
              // value={"Add a new Journey"}
              label={"Add Assessment"}
            />
          </Link> */}
        </Space>
      </div>
      {/* Listing view */}
      <div style={{ height: "85vh", overflowY: "auto" }}>
        {filteredData?.length > 0 ? (
          filteredData.map((item, index) => {
            return (
              <ListItem
                key={index}
                item={item}
                handleFavoriteToggle={handleFavoriteToggle}
                onMarkFavFinishLoad={onMarkFavFinishLoad}
              />
            );
          })
        ) : (
          <LegacyPanel
            className={"hover-section"}
            round
            disableOverflow
            style={{ height: "inherit" }}
          >
            <LegacyPanel
              flexible
              background="rgba(255,255,255,0.03)"
              paddingX
              paddingY
            >
              <LegacyPanel flexible paddingY center>
                <EmptyData />
              </LegacyPanel>
            </LegacyPanel>
          </LegacyPanel>
        )}
      </div>
      {/* 
      TODO : 
      Additional feature - choose number of items per page, go to page.
       */}
      <Pagination
        style={{ paddingTop: "16px", float: "right" }}
        current={currentPage}
        onChange={(page) => handlePaginationChange(page)}
        showSizeChanger={false}
        total={paginationCountVisible}
        pageSize={pageSize}
        hideOnSinglePage={true}
      />
    </>
  );
};
export default TestLibraryListing;
